import moment from 'moment'
import uniq from 'lodash/uniq'

export default {
  methods: {
    // Generic
    IsJsonString(str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    getArrayContent(arr) {
      if (this.IsJsonString(arr) && arr && typeof arr === 'string') {
        return JSON.parse(arr)
      }

      if (typeof arr === 'object' && arr !== null) {
        return arr
      }

      return []
    },
    getArraySize(arr) {
      if (this.IsJsonString(arr) && arr) {
        const data = JSON.parse(arr)
        return data.length
      }

      return 0
    },
    isObject(arr) {
      if (this.IsJsonString(arr)) {
        return JSON.parse(arr) instanceof Object
      } else {
        return false
      }
    },
    isArray(arr) {
      if (this.IsJsonString(arr)) {
        return JSON.parse(arr) instanceof Object
      } else {
        return false
      }
    },
    // Maths
    roundNumber(num, decimalPlaces = 3) {
      if (num < 0)
        return -this.round(-num, decimalPlaces)

      num = Math.round(num + "e" + decimalPlaces)
      return Number(num + "e" + -decimalPlaces)
    },
    // Truncate text
    truncateString(str, n, useWordBoundary) {
      if (!str) {
        return str
      }
      if (str.length <= n) {
        return str
      }
      const subString = str.substr(0, n - 1) // the original check

      return (useWordBoundary ?
        subString.substr(0, subString.lastIndexOf(' '))
        : subString) + '&hellip;'
    },
    getBuilderTextContent(rows) {
      const builderContent = []
      if (rows && rows.length > 0) {
        rows.forEach(row => {
          if (row.columns.length > 0) {
            row.columns.forEach(column => {
              if (column.contents.length > 0) {
                column.contents.forEach(content => {
                  if (content.type === 'text' || content.type === 'title') {
                    // remove nbsp
                    let newContent = content.value.replace(/&nbsp;/g, ' ')
                    newContent = newContent.replace(/(\r\n|\n|\r)/gm, ' ')
                    builderContent.push(newContent.replace(/<\/?[^>]+(>|$)/g, ""))
                  }
                })
              }
            })
          }
        })
      }

      return builderContent.join(' ')
    },
    //
    processOrder(order) {
      if (order) {
        if (order && order.user) {
          order.user = this.getArrayContent(order.user)
        }

        if (order && order.billing) {
          order.billing = this.getArrayContent(order.billing)
        }

        if (order && order.shipping) {
          order.shipping = this.getArrayContent(order.shipping)
        }

        if (order && order.delivery_options) {
          order.delivery_options = this.getArrayContent(order.delivery_options)
        }

        if (order && order.delivery_sub_options) {
          order.delivery_sub_options = this.getArrayContent(order.delivery_sub_options)
        }

        if (order && order.basket) {
          order.basket = this.getArrayContent(order.basket)
        }

        if (order && order.discount) {
          order.discount = this.getArrayContent(order.discount)
        }

        if (order && order.vouchers) {
          order.vouchers = this.getArrayContent(order.vouchers)
        }

        if (order && order.geolocation) {
          order.geolocation = this.getArrayContent(order.geolocation)
        }

        if (order && order.map_coordinates) {
          order.map_coordinates = this.getArrayContent(order.map_coordinates)
        }
      }

      return order
    },

    processTotal(order) {
      if (order) {
        const grand_total = parseFloat(order.total_cost) + parseFloat(order.total_delivery) - parseFloat(order.total_voucher) - parseFloat(order.total_discount)
        return grand_total.toFixed(2)
      }
      return '0.00'
    },

    processUserSubscription(subscription) {
      if (subscription) {
        if (subscription.billing) {
          subscription.billing = this.getArrayContent(subscription.billing)
        }

        if (subscription.details) {
          subscription.details = this.getArrayContent(subscription.details)

          subscription.details.media = this.getArrayContent(subscription.details.media)
          subscription.details.media_default = this.getArrayContent(subscription.details.media_default)
          subscription.details.images = subscription.details.media.concat(this.getArrayContent(subscription.details.media_default))
        }

        if (subscription.dispatches) {
          subscription.dispatches = this.getArrayContent(subscription.dispatches)
        }

        if (subscription.payments) {
          subscription.payments = this.getArrayContent(subscription.payments)
        }

        if (subscription.product) {
          subscription.product = this.getArrayContent(subscription.product)
        }

        if (subscription.shipping) {
          subscription.shipping = this.getArrayContent(subscription.shipping)
        }

        if (subscription.userDetails) {
          subscription.userDetails = this.getArrayContent(subscription.userDetails)
        }

        if (subscription && subscription.map_coordinates) {
          subscription.map_coordinates = this.getArrayContent(subscription.map_coordinates)
        }

        return subscription
      }
    },
    //
    processUserOrdersItems(orders) {
      const ids = []

      if (orders && orders.length) {
        orders.forEach(order => {
          const basket = this.getArrayContent(order.basket)

          basket.list.forEach(item => {
            ids.push(parseInt(item.item_id))
          })
        })
      }

      return uniq(ids)
    },
    // Date Stuff
    parseDate(date, format) {
      if (!date) return null

      return moment.unix(date).format(format)
    },
    parsePPDate(date, format) {
      if (!date) return null
      return moment(date).format(format)
    },
    parseCommentDate(date) {
      const parsedDate = moment.unix(date)
      const dateObj = {
        year: parsedDate.format('yyyy'),
        month: parsedDate.format('MMM'),
        day: parsedDate.format('D'),
        ordinal: parsedDate.format('Do').replace(/[0-9]/g, '')
      }
      return dateObj
    },
    getLocationData() {
      const data = Intl.DateTimeFormat().resolvedOptions()
      return {
        locale: data.locale,
        timeZone: data.timeZone
      }
    },
    parseMoney(value) {
      return parseFloat(value).toFixed(2)
    },
    getSnippet(text, length, clamp) {
      text = text || ''
      clamp = clamp || '...'
      length = length || 30

      if (text.length <= length) {
        return text
      }

      let tcText = text.slice(0, length - clamp.length)
      let last = tcText.length - 1

      while (last > 0 && tcText[last] !== ' ' && tcText[last] !== clamp[0]) last -= 1

      // Fix for case when text dont have any `space`
      last = last || length - clamp.length

      tcText = tcText.slice(0, last)

      return tcText + clamp
    },
    scrollTop(refName, offset) {
      offset = offset || 0
      const element = this.$refs[refName]
      if (element) {
        window.scrollTo({ top: element.offsetTop + offset, behavior: 'smooth' })
      }
    },
    parseTransList(string) {
      if (string === '') return ''
      const list = string.split(',').map(s => '<li>' + s.trim() + '</li>')

      return '<ul>' + list.join('') + '</ul>'
    },
    //
    htmmlTextParse(text) {
      if (text === '') {
        return text
      }

      const strippedString = text.replace(/(<([^>]+)>)/gi, '')

      return this.replaceHtmlEntites(strippedString)
    },
    replaceHtmlEntites(s) {
      const translateRe = /&(nbsp|amp|quot|lt|gt);/g
      const translate = { nbsp: ' ', amp: '&', quot: '"', lt: '<', gt: '>' }
      return (s.replace(translateRe, function (match, entity) {
        return translate[entity]
      }))
    },
    // Paypal Load
    loadPayPal(result) {
      const clientID = result.paypal_env === 'sandbox' ? result.paypal_sandbox : result.paypal_production

      const script = document.createElement('script')
      script.src = 'https://www.paypal.com/sdk/js?client-id=' + clientID + '&currency=GBP&components=buttons,funding-eligibility&commit=true'
      script.addEventListener('load', this.$store.commit('setPaypalLoaded', true))
      document.body.appendChild(script)
    },
    // COLUMNS
    getColumnSm(size) {
      switch (size) {
        case 3:
          return 12
        case 4:
          return 6
        case 6:
          return 6
        case 8:
          return 6
        case 9:
          return 4
        case 12:
          return 4
        default:
          return 3
      }
    },
    getColumn(size) {
      switch (size) {
        case 3:
          return 12
        case 4:
          return 6
        case 6:
          return 6
        case 8:
          return 4
        case 9:
          return 4
        default:
          return 3
      }
    },
    colourContrast(background) {
      if (background) {
        const hexToRgb = (hex) => {
          // turn hex val to RGB
          const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
          return result ?
            {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
            }
            : null
        }

        // calc to work out if it will match on black or white better
        const setContrast = rgb => (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 > 125 ? '#000' : '#fff'

        return setContrast(hexToRgb(background))
      }
    },
    hex2rgba(hex, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16))
      return `rgba(${r},${g},${b},${alpha})`
    },
    isValidPostcode (p) {
      const postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i
      return postcodeRegEx.test(p)
    },
    formatPostcode (str) {
      str = str.toUpperCase()
      str = str.replace(/[^0-9a-z]/gi, '')
      str = str.replace(/^(.*)(?=\d)/, '$1 ')
      return str
    },
    // Sleep
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time))
    },
    sleepFor(sleepDuration) {
      var now = new Date().getTime();
      while (new Date().getTime() < now + sleepDuration) {
        /* Do nothing */
      }
    }
  }
}
